
import { defineComponent, ref, nextTick, provide } from "vue";
import fixedHeader from "@/components/common/Header.vue";
import fixedFooter from "@/components/common/Footer.vue";
export default defineComponent({
  name: "Home",
  components: {
    fixedHeader,
    fixedFooter,
  },
  setup() {
    const shouldKeepAlive = ref(true);
    function destoryKeepAlive() {
      return new Promise((resolve) => {
        shouldKeepAlive.value = false;
        nextTick(() => {
          shouldKeepAlive.value = true;
          resolve(true);
        });
      });
    }

    provide("destoryKeepAlive", destoryKeepAlive);
    return {
      shouldKeepAlive
    }
  },
});
